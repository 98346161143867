* {
  box-sizing: border-box;
}

html {
  font-family: 'Arial', sans-serif;
  /* font-size: 15px; */
}

body {
  margin: 0;
  padding: 0;
  color: black;
}

h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: normal;
}



img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  &:active, &:hover {
    color: #666;
  }
}
