html, body {
  overflow-x: hidden;
}

.layout-main {
  opacity: 0;
  transition: opacity 0.5s;
}

.template-home .layout-main,
.template-about .layout-main {
  margin: 0 auto 10em;
  max-width: 1400px;
  padding: 10px;
  padding: 10px 30px ?if media $medium;
}

