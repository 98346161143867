.about {
  margin-top: 1em;
  &__item {
    margin-left: 0 !important ?if media (max-width: 600px);
    max-width: 600px;
    > *:first-child {
      margin-top: 0;
    }
    & {
      margin-top: 6em;
    }
  }
}

@media $small {
  .cols {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    > * {
      flex: 1;
      > *:first-child {
        margin-top: 0;
      }
    }
  }
}

.shows {
  &__title {
    font-size: 1em;
    font-weight: normal;
    line-height: 1.5;
    margin: 0;
  }
  &__body {
    $margin: 52px;
    position: relative;
    margin-left: $margin;
    h3 {
      font-size: 1em;
      line-height: 1.5;
      font-weight: normal;
      position: absolute;
      left: -$margin;
      width: $margin;
      margin: 0;
    }
    p {
      line-height: 1.5;
      margin: 1.5em 0;
      &:last-child {
        margin-bottom: 3em;
      }
    }
  }
}


.text {
  &__title {
    font-size: 1em;
    line-height: 1.5;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
  }
  &__body {
    h2 {
      font-size: 1.5em;
    }
    > *:last-child {
      margin-bottom: 3em;
    }
  }
}




.expand {
  cursor: pointer;
  &::after {
    margin-left: 10px;
    margin-bottom: 2px;
    display: inline-block;
    vertical-align: middle;
    content: '';
    height: 7px;
    width: 15px;
    background: no-repeat svg-load('../../img/down.svg', fill=#000);
    transition: transform 0.3s;

  }
  &.open::after {
    transform: scaleY(-1);
  }
}

.collapsed {
  overflow: hidden;
  /* display: none; */
  height: 0;
  opacity: 0;
  transition: opacity 0.7s;
  &.open {
    overflow: initial;
    /* display: block; */
    height: auto;
    opacity: 1;
  }
}