:root {
    --image-random-min: 8;
    --image-random-max: 16;
}

html {
/* font-size: 1.1em ?if media $medium;
  font-size: 1.3em ?if media $large; */
}

.random-grid {
  font-size: 0.9em;
  font-size: 1.6em ?if media $small;
  font-size: 1.8em ?if media $medium;
  font-size: 1.9em ?if media $large;
  transform: translateZ(0);
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  a {
    display: block;
    text-decoration: none;
    color: inherit;
  }
  &__text {
    max-width: 350px ?if media $small;
    padding: 0 1em;
    &:first-child {
      width: 90% ?if media $small-max;
      padding-top: 0 !important ?if media $small-max;
    }
    text-decoration: none;
    .content {
      padding: 5px;
    }
    .content > *:first-child {
      margin-top: 0;
    }
    .content > *:last-child {
      margin-bottom: 0;
    }
  }
}