@define-mixin hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}


.is-hidden {
  display: none !important;
}

.align-right {
  text-align: right;
}

%unstyled-link {
  color: inherit;
  text-decoration: none;
}

.hidden {
  display: none;
}

.sr-only {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0 #000;
}

