.template-work {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.swipe {
  overflow: hidden;
  visibility: hidden;
  height: 100%;
}
.swipe-wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  /* margin: 0 -1em; */
}
.swipe-wrap > div {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  /* margin: 0 1em; */
}



.work {
  &__item {
    padding: calc(1.4em + 20px) 0;
    position: absolute;
    left:0;
    right: 0;
    top:0;
    bottom:0;
    text-align: center;
    & > * {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    img {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto;
    }
  }
  &__embed {
    iframe {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__caption {
    position: absolute;
    left: 50px;
    font-size: 0.9em;
    bottom: calc(0.7rem + 10px);
    text-align: left;
    transform: translateY(50%);
    visibility: hidden;
    .is-info-enabled & {
      visibility: visible;
    }
  }
}

