.floatnav {
  z-index: 100;
  position: fixed;
  margin: 0.7em;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  &.info {
    bottom: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: no-repeat svg-load('../../img/info.svg', fill=#000);
  }
  &.text {
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    background: no-repeat svg-load('../../img/text.svg', fill=#000);
  }
  &.back {
    left: 0;
    top: 0;
    height: 17px;
    width: 17px;
    background: no-repeat svg-load('../../img/back.svg', fill=#000);
  }
}